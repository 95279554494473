.nex-checkbox .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #1A1A1A;
}
.nex-checkbox .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background, .nex-checkbox .mat-mdc-checkbox .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background, .nex-checkbox .mat-mdc-checkbox .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #1A1A1A;
}
.nex-checkbox .mat-mdc-checkbox .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #1A1A1A;
}
.nex-checkbox__error {
  margin-left: 10px;
}
.nex-checkbox__error .mat-mdc-form-field-error {
  color: #BA1A1A;
}
.nex-checkbox__component--error.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  border-color: #BA1A1A !important;
}
.nex-checkbox__component--error .mdc-checkbox__ripple {
  background: #BA1A1A !important;
}

.nex-chips .mdc-evolution-chip__text-label {
  align-items: center;
  display: flex;
}
.nex-chips .mdc-evolution-chip__text-label .material-icons {
  color: #1A1A1A;
  font-size: 18px;
  margin-top: 5px;
}
.nex-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled), .nex-chips.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  background-color: #FFF;
  border: 1px solid #DCDCDC;
}
.nex-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
.nex-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing, .nex-chips.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label,
.nex-chips.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__icon--trailing {
  color: #1A1A1A;
}
.nex-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mdc-evolution-chip--disabled .mdc-evolution-chip__text-label, .nex-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mdc-evolution-chip--disabled .mdc-evolution-chip__icon--trailing, .nex-chips.mat-mdc-standard-chip.mdc-evolution-chip--disabled.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label, .nex-chips.mat-mdc-standard-chip.mdc-evolution-chip--disabled.mdc-evolution-chip--disabled .mdc-evolution-chip__icon--trailing {
  color: #4E4E4E;
}

.nex-selectable-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #FFF !important;
  border: 1px solid #DCDCDC;
}
.nex-selectable-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label, .nex-selectable-chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__checkmark {
  color: #1A1A1A;
}
.nex-selectable-chips.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  color: #4E4E4E;
}
.nex-selectable-chips.mat-mdc-chip-selected.mdc-evolution-chip--selected {
  background-color: #DCDCDC !important;
}

div.mat-mdc-paginator-container {
  justify-content: space-between;
}

.nex-alert-notification-container {
  width: 100%;
  padding: 16px 0px 16px 0px;
  border-radius: 12px;
  border: 1px solid #D0D0D0;
}
.nex-alert-notification-container.__success {
  border: 1px solid #41A57B;
}
.nex-alert-notification-container.__warn {
  border: 1px solid #F28C4A;
}
.nex-alert-notification-container.__danger {
  border: 1px solid #BA1A1A;
}
.nex-alert-notification-container.__info {
  border: 1px solid #D0D0D0;
}
.nex-alert-notification-container.__dark {
  background-color: #1A1A1A;
  color: #DCDCDC;
}
.nex-alert-notification-container.__pinned {
  border-radius: unset;
}
.nex-alert-notification-container__header {
  display: flex;
  padding-right: 5px;
  gap: 16px;
}
.nex-alert-notification-container__header-icon {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 0px 0px 16px;
}
.nex-alert-notification-container__header-icon.__success {
  color: #41A57B;
}
.nex-alert-notification-container__header-icon.__warn {
  color: #F28C4A;
}
.nex-alert-notification-container__header-icon.__danger {
  color: #BA1A1A;
}
.nex-alert-notification-container__header-icon.__info {
  color: #D0D0D0;
}
.nex-alert-notification-container__header-icon.__dark {
  color: #DCDCDC;
}
.nex-alert-notification-container__header-description {
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.nex-alert-notification-container__header-description.__pinned {
  flex-direction: row;
  justify-content: unset;
  align-items: center;
  gap: 16px;
}
.nex-alert-notification-container__header-description-title {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.nex-alert-notification-container__header-description-text {
  margin: 0;
}
.nex-alert-notification-container__header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

@media (max-width: 600px) {
  .nex-alert-notification-container {
    display: flex;
    width: 100%;
    padding: 4px 16px 16px 16px;
    border-radius: 12px;
    border: 1px solid #D0D0D0;
  }
  .nex-alert-notification-container.__success {
    border: 1px solid #41A57B;
  }
  .nex-alert-notification-container.__warn {
    border: 1px solid #F28C4A;
  }
  .nex-alert-notification-container.__danger {
    border: 1px solid #BA1A1A;
  }
  .nex-alert-notification-container.__info {
    border: 1px solid #D0D0D0;
  }
  .nex-alert-notification-container__header {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    width: 100%;
  }
  .nex-alert-notification-container__header-col-icon {
    display: inline-flex;
    vertical-align: top;
    justify-content: center;
    padding-right: 16px;
    padding-top: 13px;
  }
  .nex-alert-notification-container__header-col-icon--content.__success {
    color: #41A57B;
  }
  .nex-alert-notification-container__header-col-icon--content.__warn {
    color: #F28C4A;
  }
  .nex-alert-notification-container__header-col-icon--content.__danger {
    color: #BA1A1A;
  }
  .nex-alert-notification-container__header-col-icon--content.__info {
    color: #D0D0D0;
  }
  .nex-alert-notification-container__header-col-icon--content.__dark {
    color: #DCDCDC;
  }
  .nex-alert-notification-container__header-col-items {
    display: inline-block;
    align-items: center;
    justify-content: center;
  }
  .nex-alert-notification-container__header-col-items-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nex-alert-notification-container__header-col-items-row-actions {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    gap: 16px;
  }
  .nex-alert-notification-container__header-col-items-row-actions--btn {
    margin-left: 16px;
  }
  .nex-alert-notification-container__header-col-items-row-title {
    width: 66.6666666667%;
    display: inline-block;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
  }
  .nex-alert-notification-container__header-col-items-row-title--text {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 18px !important;
  }
  .nex-alert-notification-container__header-col-items-row-close {
    display: inline-block;
    vertical-align: middle;
  }
  .nex-alert-notification-container__header-col-items-row-close--btn {
    margin-left: 16px;
  }
}